import React from 'react'
import { ThemeProvider } from 'styled-components'

import { CookieConsent } from 'components'

import Header from './Header'
import Footer from './Footer'
import { GlobalStyle, defaultTheme } from '../styles'

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <Header />
      <div className="main">{children}</div>
      <CookieConsent />
      <Footer />
    </ThemeProvider>
  )
}

export default Layout
