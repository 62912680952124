import styled from 'styled-components'
import { neutral, typeScale } from 'styles'

export const FooterWrapper = styled.div`
  background: #141416;
  color: ${neutral[500]};
  font-size: ${typeScale.copyrightText};

  .footer-content {
    padding: 2rem 0.5rem;
    border-top: 1px solid ${neutral[600]};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .links {
    display: flex;
    align-items: center;
  }

  .link {
    margin: 0 1rem;
    text-decoration: none;
    color: ${neutral[500]};
    transition: all 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover {
      color: ${neutral[100]};
    }
  }
`
