import React from 'react'

const HomeIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9918 4.17144L12.0001 4.1671L12.0089 4.17163L16.4623 6.8437L18.0001 7.77022V18.8003C18.0001 19.3933 17.9986 19.7179 17.9795 19.951L17.9772 19.9774L17.9509 19.9797C17.7177 19.9987 17.3932 20.0003 16.8001 20.0003H7.20012C6.60707 20.0003 6.28256 19.9987 6.04939 19.9797L6.02302 19.9774L6.02073 19.951C6.00168 19.7179 6.00012 19.3933 6.00012 18.8003V7.77025L7.53229 6.84712L11.9918 4.17144ZM6.50181 5.13303L1.48566 8.14272C1.01208 8.42687 0.85851 9.04113 1.14266 9.51471C1.42681 9.98829 2.04107 10.1419 2.51465 9.85771L4.00012 8.96642V18.8003C4.00012 19.9204 4.00012 20.4804 4.21811 20.9083C4.40986 21.2846 4.71582 21.5906 5.09214 21.7823C5.51996 22.0003 6.08002 22.0003 7.20012 22.0003H16.8001C17.9202 22.0003 18.4803 22.0003 18.9081 21.7823C19.2844 21.5906 19.5904 21.2846 19.7821 20.9083C20.0001 20.4804 20.0001 19.9204 20.0001 18.8003V8.96639L21.4857 9.85771C21.9592 10.1419 22.5735 9.98829 22.8576 9.51471C23.1418 9.04113 22.9882 8.42687 22.5146 8.14272L17.4927 5.12957L13.6515 2.81528C13.3723 2.64702 13.158 2.5179 12.9763 2.42081C12.9339 2.3971 12.8909 2.37505 12.8473 2.35465C12.6962 2.28089 12.5651 2.2318 12.4305 2.20213C12.147 2.13965 11.8533 2.13965 11.5697 2.20214C11.4348 2.23188 11.3034 2.28115 11.1518 2.35523C11.109 2.37529 11.0667 2.39695 11.0251 2.42021C10.8432 2.51737 10.6286 2.64667 10.3487 2.81528L6.50181 5.13303Z"
          fill="#FFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13 20V16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16V20H13ZM12 13C10.3431 13 9 14.3431 9 16V22H15V16C15 14.3431 13.6569 13 12 13Z"
          fill="#FFF"
        />
      </svg>
    </>
  )
}
const AboutIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 13C8.13401 13 5 16.134 5 20V22C5 22.5523 4.55228 23 4 23C3.44772 23 3 22.5523 3 22V20C3 15.0294 7.02944 11 12 11C16.9706 11 21 15.0294 21 20V22C21 22.5523 20.5523 23 20 23C19.4477 23 19 22.5523 19 22V20C19 16.134 15.866 13 12 13Z"
          fill="#FFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11ZM12 13C15.3137 13 18 10.3137 18 7C18 3.68629 15.3137 1 12 1C8.68629 1 6 3.68629 6 7C6 10.3137 8.68629 13 12 13Z"
          fill="#FFF"
        />
      </svg>
    </>
  )
}
const WorksIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.5 15C17.0478 15 16.4089 15.0625 15.7438 15.1536C15.4331 15.1962 15.1962 15.4331 15.1536 15.7438C15.0625 16.4089 15 17.0478 15 17.5C15 17.9522 15.0625 18.5911 15.1536 19.2562C15.1962 19.5669 15.4331 19.8038 15.7438 19.8464C16.4089 19.9375 17.0478 20 17.5 20C17.9522 20 18.5911 19.9375 19.2562 19.8464C19.5669 19.8038 19.8038 19.5669 19.8464 19.2562C19.9375 18.5911 20 17.9522 20 17.5C20 17.0478 19.9375 16.4089 19.8464 15.7438C19.8038 15.4331 19.5669 15.1962 19.2562 15.1536C18.5911 15.0625 17.9522 15 17.5 15ZM15.4724 13.1721C14.2725 13.3364 13.3364 14.2725 13.1721 15.4724C13.0778 16.1606 13 16.9082 13 17.5C13 18.0918 13.0778 18.8394 13.1721 19.5276C13.3364 20.7275 14.2725 21.6636 15.4724 21.8279C16.1606 21.9222 16.9082 22 17.5 22C18.0918 22 18.8394 21.9222 19.5276 21.8279C20.7275 21.6636 21.6636 20.7275 21.8279 19.5276C21.9222 18.8394 22 18.0918 22 17.5C22 16.9082 21.9222 16.1606 21.8279 15.4724C21.6636 14.2725 20.7275 13.3364 19.5276 13.1721C18.8394 13.0778 18.0918 13 17.5 13C16.9082 13 16.1606 13.0778 15.4724 13.1721Z"
          fill="#FFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.5 15C6.04782 15 5.40893 15.0625 4.74381 15.1536C4.43308 15.1962 4.19615 15.4331 4.15359 15.7438C4.0625 16.4089 4 17.0478 4 17.5C4 17.9522 4.0625 18.5911 4.15359 19.2562C4.19615 19.5669 4.43308 19.8038 4.74381 19.8464C5.40893 19.9375 6.04782 20 6.5 20C6.95218 20 7.59107 19.9375 8.25619 19.8464C8.56692 19.8038 8.80385 19.5669 8.84641 19.2562C8.9375 18.5911 9 17.9522 9 17.5C9 17.0478 8.9375 16.4089 8.84641 15.7438C8.80385 15.4331 8.56692 15.1962 8.25619 15.1536C7.59107 15.0625 6.95218 15 6.5 15ZM4.47244 13.1721C3.27253 13.3364 2.33642 14.2725 2.17209 15.4724C2.07784 16.1606 2 16.9082 2 17.5C2 18.0918 2.07784 18.8394 2.17209 19.5276C2.33642 20.7275 3.27253 21.6636 4.47244 21.8279C5.16065 21.9222 5.90816 22 6.5 22C7.09184 22 7.83935 21.9222 8.52756 21.8279C9.72747 21.6636 10.6636 20.7275 10.8279 19.5276C10.9222 18.8394 11 18.0918 11 17.5C11 16.9082 10.9222 16.1606 10.8279 15.4724C10.6636 14.2725 9.72747 13.3364 8.52756 13.1721C7.83935 13.0778 7.09184 13 6.5 13C5.90816 13 5.16065 13.0778 4.47244 13.1721Z"
          fill="#FFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.5 4C17.0478 4 16.4089 4.0625 15.7438 4.15359C15.4331 4.19615 15.1962 4.43308 15.1536 4.74381C15.0625 5.40893 15 6.04782 15 6.5C15 6.95218 15.0625 7.59107 15.1536 8.25619C15.1962 8.56692 15.4331 8.80385 15.7438 8.84641C16.4089 8.9375 17.0478 9 17.5 9C17.9522 9 18.5911 8.9375 19.2562 8.84641C19.5669 8.80385 19.8038 8.56692 19.8464 8.25619C19.9375 7.59107 20 6.95218 20 6.5C20 6.04782 19.9375 5.40893 19.8464 4.74381C19.8038 4.43308 19.5669 4.19615 19.2562 4.15359C18.5911 4.0625 17.9522 4 17.5 4ZM15.4724 2.17209C14.2725 2.33642 13.3364 3.27253 13.1721 4.47244C13.0778 5.16065 13 5.90816 13 6.5C13 7.09184 13.0778 7.83935 13.1721 8.52756C13.3364 9.72747 14.2725 10.6636 15.4724 10.8279C16.1606 10.9222 16.9082 11 17.5 11C18.0918 11 18.8394 10.9222 19.5276 10.8279C20.7275 10.6636 21.6636 9.72747 21.8279 8.52756C21.9222 7.83935 22 7.09184 22 6.5C22 5.90816 21.9222 5.16065 21.8279 4.47244C21.6636 3.27253 20.7275 2.33642 19.5276 2.17209C18.8394 2.07784 18.0918 2 17.5 2C16.9082 2 16.1606 2.07784 15.4724 2.17209Z"
          fill="#FFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.5 4C6.04782 4 5.40893 4.0625 4.74381 4.15359C4.43308 4.19615 4.19615 4.43308 4.15359 4.74381C4.0625 5.40893 4 6.04782 4 6.5C4 6.95218 4.0625 7.59107 4.15359 8.25619C4.19615 8.56692 4.43308 8.80385 4.74381 8.84641C5.40893 8.9375 6.04782 9 6.5 9C6.95218 9 7.59107 8.9375 8.25619 8.84641C8.56692 8.80385 8.80385 8.56692 8.84641 8.25619C8.9375 7.59107 9 6.95218 9 6.5C9 6.04782 8.9375 5.40893 8.84641 4.74381C8.80385 4.43308 8.56692 4.19615 8.25619 4.15359C7.59107 4.0625 6.95218 4 6.5 4ZM4.47244 2.17209C3.27253 2.33642 2.33642 3.27253 2.17209 4.47244C2.07784 5.16065 2 5.90816 2 6.5C2 7.09184 2.07784 7.83935 2.17209 8.52756C2.33642 9.72747 3.27253 10.6636 4.47244 10.8279C5.16065 10.9222 5.90816 11 6.5 11C7.09184 11 7.83935 10.9222 8.52756 10.8279C9.72747 10.6636 10.6636 9.72747 10.8279 8.52756C10.9222 7.83935 11 7.09184 11 6.5C11 5.90816 10.9222 5.16065 10.8279 4.47244C10.6636 3.27253 9.72747 2.33642 8.52756 2.17209C7.83935 2.07784 7.09184 2 6.5 2C5.90816 2 5.16065 2.07784 4.47244 2.17209Z"
          fill="#FFF"
        />
      </svg>
    </>
  )
}
const ArticlesIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 22C3 21.4477 3.44772 21 4 21H20C20.5523 21 21 21.4477 21 22C21 22.5523 20.5523 23 20 23H4C3.44772 23 3 22.5523 3 22Z"
          fill="#FFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.7071 3.12102C16.3166 2.73049 15.6834 2.73049 15.2929 3.12102L14.4142 3.99967L18 7.58545L18.8787 6.7068C19.2692 6.31628 19.2692 5.68312 18.8787 5.29259L16.7071 3.12102ZM6.91425 11.4997L13 5.41388L16.5858 8.99967L10.5 15.0855L6.91425 11.4997ZM5.50003 12.9139L5 13.4139V16.9997H8.58579L9.08582 16.4997L5.50003 12.9139ZM13.8787 1.7068C15.0503 0.535232 16.9497 0.535232 18.1213 1.7068L20.2929 3.87838C21.4645 5.04995 21.4645 6.94945 20.2929 8.12102L10 18.4139C9.62493 18.789 9.11622 18.9997 8.58579 18.9997H5C3.89543 18.9997 3 18.1043 3 16.9997V13.4139C3 12.8835 3.21071 12.3748 3.58579 11.9997L13.8787 1.7068Z"
          fill="#FFF"
        />
      </svg>
    </>
  )
}
const ContactIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5ZM4 3C2.34315 3 1 4.34315 1 6V18C1 19.6569 2.34315 21 4 21H20C21.6569 21 23 19.6569 23 18V6C23 4.34315 21.6569 3 20 3H4Z"
          fill="#FFF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.2318 7.35984C5.58537 6.93556 6.21593 6.87824 6.64021 7.2318L11.3598 11.1648C11.7307 11.4739 12.2694 11.4739 12.6402 11.1648L17.3598 7.2318C17.7841 6.87824 18.4147 6.93556 18.7682 7.35984C19.1218 7.78412 19.0645 8.41468 18.6402 8.76825L13.9206 12.7013C12.808 13.6284 11.192 13.6284 10.0795 12.7013L5.35984 8.76825C4.93556 8.41468 4.87824 7.78412 5.2318 7.35984Z"
          fill="#FFF"
        />
      </svg>
    </>
  )
}
const BlogIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5ZM4 3C2.34315 3 1 4.34315 1 6V18C1 19.6569 2.34315 21 4 21H20C21.6569 21 23 19.6569 23 18V6C23 4.34315 21.6569 3 20 3H4Z"
          fill="#FFF"
        />
        <path
          d="M12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13H18C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11H12Z"
          fill="#FFF"
        />
        <path
          d="M6 15C5.44772 15 5 15.4477 5 16C5 16.5523 5.44772 17 6 17H12C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15H6Z"
          fill="#FFF"
        />
        <path
          d="M15 16C15 15.4477 15.4477 15 16 15H18C18.5523 15 19 15.4477 19 16C19 16.5523 18.5523 17 18 17H16C15.4477 17 15 16.5523 15 16Z"
          fill="#FFF"
        />
        <path
          d="M6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H8C8.55229 13 9 12.5523 9 12C9 11.4477 8.55229 11 8 11H6Z"
          fill="#FFF"
        />
      </svg>
    </>
  )
}

export const menu = [
  {
    title: 'Home',
    icon: <HomeIcon />,
    target: 'hero',
    position: 0,
  },
  {
    title: 'About',
    icon: <AboutIcon />,
    target: 'about',
    position: 53,
  },
  {
    title: 'Works',
    icon: <WorksIcon />,
    target: 'works',
    position: 107,
  },
  {
    title: 'Articles',
    icon: <ArticlesIcon />,
    target: 'blog',
    position: 217,
  },
  {
    title: 'Contact',
    icon: <ContactIcon />,
    target: 'contact',
    position: 263,
  },
]

export const pages = [
  {
    title: 'Blog',
    icon: <BlogIcon />,
    target: '/blog',
  },
]
