import React from 'react'

import { FooterWrapper } from './Footer.style'

const Footer = () => {
  return (
    <FooterWrapper>
      <div className="container">
        <div className="footer-content">
          <div className="copyright">
            <span className="copy">
              Copyright &copy; 2021 viktorsdk. All rights reserved
            </span>
          </div>
          <div className="links">
            <a className="link" href="/disclaimer" target="_blank">
              Disclaimer
            </a>
            <a className="link" href="/privacy" target="_blank">
              Privacy
            </a>
            <a className="link" href="/toc" target="_blank">
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
    </FooterWrapper>
  )
}

export default Footer
