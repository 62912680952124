import React from 'react'
import { Link } from 'react-scroll'

import { Button, ArrowRightAnimated } from 'components'
import useWindowSize from 'hooks/useWindowSize'
import Logo from 'assets/images/logo.svg'

import MobileHeader from './MobileHeader'

import {
  HeaderWrapper,
  HeaderContent,
  Navigation,
  ActionButtons,
  LogoBorder,
  NavList,
  NavItem,
} from './Header.style'

const Header = () => {
  const { width } = useWindowSize()

  return (
    <HeaderWrapper>
      <div className="container">
        {width > 750 ? (
          <HeaderContent>
            <Navigation>
              <Logo />
              <LogoBorder />
              <NavList>
                <Link to="hero" smooth spy>
                  <NavItem>Home</NavItem>
                </Link>
                <Link to="about" smooth spy offset={-100}>
                  <NavItem>About</NavItem>
                </Link>
                <Link to="works" smooth spy>
                  <NavItem>Works</NavItem>
                </Link>
                <Link to="blog" smooth spy>
                  <NavItem>Latest Articles</NavItem>
                </Link>
              </NavList>
            </Navigation>
            <ActionButtons>
              <Link to="contact" smooth spy>
                <Button.SecondaryButton>
                  Contact
                  <Button.Icon className="arrow-right-animated">
                    <ArrowRightAnimated />
                  </Button.Icon>
                </Button.SecondaryButton>
              </Link>
            </ActionButtons>
          </HeaderContent>
        ) : (
          <MobileHeader />
        )}
      </div>
    </HeaderWrapper>
  )
}

export default Header
