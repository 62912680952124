import React, { useRef } from 'react'
import { motion, useCycle } from 'framer-motion'
import { Link } from 'react-scroll'

import { MenuToggle } from './MenuToggle'
import { menu } from './menu'
import { MobileNavWrapper, Background } from './Header.style'

const MobileHeader = () => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)

  const menuVariants = {
    open: (height = 550) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
      transition: {
        type: 'spring',
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: 'circle(30px at 40px 40px)',
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
  }

  const navigationVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  }

  const menuItemVariants = {
    open: {
      display: 'flex',
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      display: 'none',
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }

  const wrapperVariants = {
    open: {
      maxHeight: 550,
    },
    closed: {
      maxHeight: 75,
      transition: { delay: 1 },
    },
  }

  const MenuItem = ({ title, icon, toggleOpen, target }) => {
    return (
      <Link
        to={target}
        smooth={true}
        ignoreCancelEvents={true}
        spy={true}
        activeClass="active"
        onClick={() => toggleOpen()}
      >
        <motion.li
          variants={menuItemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <div className="icon">{icon}</div>
          <div className="text">{title}</div>
        </motion.li>
      </Link>
    )
  }

  const Navigation = ({ toggleOpen }) => (
    <motion.ul variants={navigationVariants}>
      {menu.map(i => (
        <MenuItem
          key={i}
          title={i.title}
          icon={i.icon}
          toggleOpen={toggleOpen}
          target={i.target}
        />
      ))}
    </motion.ul>
  )

  return (
    <MobileNavWrapper
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      ref={containerRef}
      custom={550}
      variants={wrapperVariants}
    >
      <Background variants={menuVariants} />
      <Navigation toggleOpen={toggleOpen} />
      <MenuToggle toggle={() => toggleOpen()} />
    </MobileNavWrapper>
  )
}

export default MobileHeader
