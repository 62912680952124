import styled from 'styled-components'
import { neutral, typeScale, darkBlue } from 'styles'
import { motion } from 'framer-motion'

export const HeaderWrapper = styled.nav`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
  height: 80px;
  transition: all 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 0 0 12px 12px;
`
export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: 1rem 0;
`
export const Navigation = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const ActionButtons = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const LogoBorder = styled.div`
  width: 2px;
  height: 40px;
  background-color: #353945;
  margin: 0 0 0 2rem;
`
export const NavList = styled.ul`
  padding-top: 2px;
  height: 100%;
  display: flex;
  align-items: center;
`

export const NavItem = styled.li`
  list-style: none;
  margin-right: 2rem;
  cursor: pointer;
  color: ${neutral[500]};
  font-size: ${typeScale['navText']};
  font-weight: 600;

  transition: all 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover {
    color: ${neutral[200]};
  }
`

export const MobileNavWrapper = styled(motion.div)`
  @media (min-width: 1216px) {
    display: none;
  }
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 2;
  button {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 18px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
    z-index: 9999;
  }
  ul {
    padding: 25px;
    position: absolute;
    top: 80px;
    width: 230px;
    z-index: 9999;
  }
  li {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 1;
  }
  .icon {
    width: 40px;
    height: 40px;
    flex: 40px 0;
    margin-right: 0px;
    margin-left: 20px;
    margin-top: 4px;
  }
  .text {
    width: 200px;
    height: 40px;
    flex: 1;
    color: #fff;
    font-weight: 600;
    font-size: 21px;
  }
  .active {
    color: #22d3ee !important;
  }
`

export const Background = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  width: 250px;
  background: rgba(23, 23, 25, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
`
