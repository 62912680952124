import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import Layout from 'layout'
import { Hero, About, Works, Blog, Contact } from 'container'
import projects from 'data/projects.json'

const Home = () => {
  const [currentProject, setCurrentProject] = useState(projects.data[0])

  return (
    <>
      <Helmet>
        <title>viktorsdk - Your Partner in Web Technology</title>
        <meta
          name="description"
          content="As a Software Engineer with an eye for design and user experience, I craft quality software from front to back - every aspect considered. You can call me your Swiss Army Knife in terms of Software."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale = 0.86, maximum-scale=3.0, minimum-scale=0.86"
        />
      </Helmet>
      <Layout>
        <Hero />
        <About
          currentProject={currentProject}
          setCurrentProject={setCurrentProject}
        />
        <Works
          currentProject={currentProject}
          setCurrentProject={setCurrentProject}
        />
        <Blog />
        <Contact />
      </Layout>
    </>
  )
}

export default Home
